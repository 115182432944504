.block-image {
  position: relative;
  margin: var(--padding-m) 0;

  &.--small {
    padding: 0 var(--padding-xl);
  }

  &.--mid {
    padding: 0 var(--padding-l);
  }

  &.--large {
    padding: 0 var(--padding-s);
  }

  &.--xlarge {
    padding: 0 var(--padding-xs);
  }

  &.--full {}

  &.--frames {
   // height: 60vh;
  }

  &.--hide {
    figure {
      visibility: hidden;
    }
  }
}

.block-image.--frames ul {
  /*position: absolute;
  top:0;
  left:0;
  height: 100%;*/


  list-style: none;
  margin: 0;
  width: 100%;
  display: grid;
  grid-template: auto / 1fr 1fr;
  grid-gap: var(--padding-xs);
  padding: 0;
  //padding: 0 20vw 0 20vw;


  li {
    position: relative;
    display: block;


    /*img {
      height: 100% !important;
    }*/


    /*.media-holder {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      padding-top: 0;
    }*/
  }
}


@media (max-width: $smartphone) {
  .block-image {
    &.--small {
      padding: 0 var(--padding-xs);
    }

    &.--mid {
      padding: 0 var(--padding-xs);
    }

    &.--large {
      padding: 0 var(--padding-xs);
    }

    &.--xlarge {
      padding: 0 var(--padding-xs);
    }

    &.--hide {
      figure {
        visibility: visible;
      }
    }
  }

  .block-image.--frames ul {
    grid-template: auto / auto;
  }
}
