.blockquote-estrobo {
  position: relative;
  
  margin: 0 0 var(--padding-xl) 0;
  color: var(--white);
  height: 200vh;
  pointer-events: none;

  &.--with-images {
    height: 400vh;
  }
}



.blockquote-estrobo .holder-content {
  position: relative;
  padding: var(--padding-l) var(--padding-m) var(--padding-l) 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  min-height: 100vh;
}


.blockquote-estrobo .content {
  position: relative;
  @include font-titular-xlarge();
  text-align: right;

  span {
    display: inline-block;
    width: 100%;
    color: var(--color-primary);

    &.__t {
      > span,
      > div {
        opacity: 0;
      }
    }
  }

  .deco {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    color: var(--color-primary);
    z-index: -1;
    opacity: 0;
  }
}

.blockquote-estrobo.--vertical-ref .content {
  font-size: 6vh;
  text-align: center;
}



.blockquote-estrobo__images-holder {
  position: absolute;
  top:0;
  left:0;
  width: auto;
  height: 100vh;
  opacity: 0;
  display: flex;
  align-items: center;

  list-style: none;
  margin: 0;
  padding: 0 0 0 100vw;

  li {
    display: block;
    width: 33vw;
    margin: 0 2vw;
  }

}

@media (max-width: $smartphone) {
  .blockquote-estrobo {
    height: auto;
    min-height: 100vh;

    &.--with-images {
      height: auto;
      min-height: 100vh;
    }
  }

  .blockquote-estrobo.--vertical-ref .holder-content {
    padding: 0;
  }

  .blockquote-estrobo.--vertical-ref {
    overflow: hidden;
  }

  .blockquote-estrobo.--vertical-ref .content {
    font-size: 6vw;
  }

  .blockquote-estrobo__images-holder {
    li {
      display: block;
      width: 90vw;
      margin: 0 5vw;
    }
  }
}