.block-slider-video {
  position: relative;
  height: 300vh;
}

.block-slider-video__slider ul {
  position: relative;
  width: 100vw;
  height: 100vh;

  list-style: none;
  margin: 0;
  padding: 0;

  color: var(--white);
}

.block-slider-video ul li {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-m);

  &:not(.__active) {
    pointer-events: none;
    //opacity: 0;
  }

  &.__active {
    pointer-events: all;
  }

  figure {
    position: absolute;
    top:-10%;
    left:0;
    width: 100%;
    height: 120%;

    &:after {
      @include pseudo-element-absolute();
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      background: url($images-path + "scanlines.png");
      background-color: rgba(0,0,0,.6);
      opacity: .6;
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  h3 {
    position: relative;
    max-width: 100%;

    font-family: var(--font-sans);
    font-size: 15vh;
    font-weight: 700;
    line-height: var(--line-height-xlarge);
    text-transform: uppercase;

    margin: 0 0 .3em;

    > span {
      display: block;
    }
  }

  p {
    position: relative;
    max-width: 50%;
    @include p--default();
  }
}

.block-slider-video .controls {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100vh;
  padding: var(--padding-m);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  button {
    @include basic-a();
    background-color: var(--white);

    font-family: var(--font-sans);
    font-weight: 800;
    line-height: 1;
    font-size: var(--font-size-slider-controls);
    color: var(--black);

    padding: .1em .2em;


    &:first-of-type {
      margin-right: var(--padding-xs);
    }
  }
}

@media (max-width: $smartphone) {
  .block-slider-video {
    height: 100vh;
  }

  .block-slider-video ul li {
    height: 100%;

    h3 {
      font-size: 48px;
    }

    p {
      position: relative;
      max-width: 100%;
      font-size: 14px;
    }

    figure {
      height: 100%;
      top:0;
    }
  }

  .block-slider-video .controls {
    button {
      font-size: 7vw;
    }
  }
}