$z-index-bg: 1;
$z-index-wrap: 2;
$z-index-cookies: 3;
$z-index-goto-main: 4;
$z-index-progress: 5;
$z-index-categories: 5;
$z-index-sidemenu: 6;
$z-index-header: 7;
$z-index-loader: 8;

$z-index-windows: 9;

$z-index-cursor: 10;
$z-index-interface: 10;

$z-index-canvas: -1;