#Footer {
  margin: 0 0 0;
  padding: 0 0 var(--padding-xl);
  color: var(--frills-orange);

  > .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 var(--padding-m);
  }

  > figure {
    margin: var(--padding-m) 0;
  }
}

.footer__deco {
  margin-bottom: var(--padding-l);

  img {
    width: 100%;
    height: auto;
  }
}

.footer__social {
  flex: 1 0 100px;

  list-style: none;
  padding: 0;
  margin: 0;

  font-family: var(--font-sans);
  font-weight: 400;
  font-size: var(--font-size-base);

  li {
    display: block;
  }

  a {
    color: currentColor;
  }
}

.footer__adress {
  flex: 2 0 100px;

  font-family: var(--font-sans);
  font-weight: 400;
  font-size: var(--font-size-base);
  font-style: normal;

  a {
    color: currentColor;
  }
}

.footer__email {
  flex: 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: var(--font-sans);
  font-weight: 700;
  font-size: var(--font-size-large);
  line-height: 1;
  text-transform: uppercase;

  background-color: var(--frills-orange);
  color: var(--black);
  text-decoration: none;
}

.footer__legal {
  width: 100%;
  list-style: none;
  padding: 0 var(--padding-m);
  margin: var(--padding-s) 0 0;

  font-family: var(--font-sans);
  font-weight: 400;
  font-size: var(--font-size-xsmall);
  text-transform: uppercase;
  text-align: right;

  li {
    display: inline-block;
    margin-left: 1.2em;
  }

  a {
    color: currentColor;
  }
}

@media (max-width: $smartphone) {
  .footer__adress {
    text-align: right;
  }

  .footer__email {
    flex: 0 0 100%;
    margin: var(--padding-m) 0;
  }
}