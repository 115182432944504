#Interface__Canvas {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  @include z-index($z-index-canvas);
  pointer-events: none;

  .__loading & {
    pointer-events: all;
  }
}

#IntersectionBG {
  position: relative;
  top:0%;
  opacity: .1;
  left:0;
  width: 100vh;
  height: 100vh;
  background-color: #1ed36f;
}


#Interface_x_Canvas {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  @include z-index($z-index-canvas);
  pointer-events: none;
}

#Interface > figure {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 150%;
  @include z-index($z-index-canvas);
  pointer-events: none;

  &.--hide {
    visibility: hidden;
  }

  .tapa,
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  video {
    @include z-index(0);
  }

  .tapa {
    @include z-index(1);
    background: var(--black);
  }
}

#VideosPrinciples {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 150%;
  @include z-index($z-index-canvas);

  &.--hide {
    visibility: hidden;
  }

  > figure {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
  }

  .tapa,
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  video {
    @include z-index(0);
  }

  .tapa {
    @include z-index(1);
    background: var(--black);
  }
}