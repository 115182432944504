#Message {
    position: fixed;
    @include z-index($z-index-interface);
    top: 10vh;
    left : 20%;
    width: 60%;
    height: 80vh;
    background-color: var(--black);

    > button {
        position: absolute;
        @include z-index(2);
        top: 20px;
        right: 20px;
        @include basic-a();


        display: block;
        outline: 0;
        text-decoration: none;

        background-color: var(--color-aux);
        color: var(--color-primary);
        border: 1px solid var(--color-primary);
        font-family: var(--font-sans);
        font-weight: 800;
        line-height: 1;
        font-size: var(--font-size-base);
        text-transform: uppercase;
        padding: 0.1em 0.2em;
    }

    > iframe {
        position: absolute;
        @include z-index(1);
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    &[aria-expanded="true"] {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }

    &[aria-expanded="false"] {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
}
