
body{
  width: 100%;
  min-height: 100%;
  min-height: -webkit-fill-available;
  background-color: var(--black);
  color: var(--color-primary);

  &.--bg-orange {
    background-color: var(--frills-orange);
    --color-primary: var(--black);
    --color-secondary: var(--white);
    --color-aux: var(--frills-orange);
  }
  &.--bg-black {
    background-color: var(--black);
    --color-primary: var(--frills-orange);
    --color-secondary: var(--white);
    --color-aux: var(--black);
  }

  will-change: background-color;
  transition: background-color .2s var(--ease-out-quad);

  &:not(.__scroll-manual){
    overflow: hidden;

    main {
      position: fixed;
      width: 100%;
      height: 100%;
      top:0;
      left:0;
    }
  }

  &.__scroll-manual {
    overflow-y: scroll;
    overflow-x: hidden;
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
    -webkit-overflow-scrolling: touch;
  }
}

main {
  width: 100%;
}

@media (max-width: $largeScreen) {
  body {}
}

@media (max-width: $smartphone) {
  body {}
}
/* BOOKING
#contentholder {
  max-width:900px;
  margin:0 auto;
}
#myBody {
  background:#000000;
  color:#ffffff;
}
.sone-general-formcontainer {
  background:#000000 !important;
  border:0 !important;
}
.sone-general-header,
.sone-general-header-label,
.sone-general-subheader {
  color: #fa5117 !important;
  font-weight:700;
  background-color: #000000 !important;
  text-transform: uppercase !important;
  border: 0 !important;
}

.s1_button {
  color: #ffffff !important;
  background-color: #fa5117 !important;
  text-transform: uppercase !important;
  font-size: 2em !important;
  font-weight:700;
  margin:0 auto;
}

.sone-general-header::before {
  content: " ";
  display:block;
  width:100%;
  background:#fa5117;
  padding-top:52.5%;
  background: url("https://www.frills.de/assets/social/facebook-1200x630.jpg") no-repeat;
  background-size: contain;
  margin-bottom: 1em;
}*/