:root {
  --font-sans: 'Montserrat';
  --font-mono: 'IBM Plex Mono', monospace;

  /*--font-size-ratio: #{8/9};

  --font-size-xxlarge: #{(90px / 16px) * 1rem};
  --font-size-xlarge: #{(120px / 16px) * 1rem};
  --font-size-mid: #{(48px / 16px) * 1rem};

  --font-size-cart: #{(24px / 16px) * 1rem};
  --font-size-form: #{(32px / 16px) * 1rem};
  --font-size-form-em: #{(32px / 16px) * 1em};


  --font-size-xsmall: #{(90px / 16px) * 1rem};*/



  //USADOS
  --font-size-xxlarge: #{(192px / 16px) * 1rem};
  --font-size-xlarge: #{(90px / 16px) * 1rem};
  --font-size-large: #{(45px / 16px) * 1rem};
  --font-size-base: #{(18px / 16px) * 1rem};
  --font-size-small: #{(12px / 16px) * 1rem};
  --font-size-xsmall: #{(10px / 16px) * 1rem};

  --font-size-sidemenu: #{(18px / 16px) * 1rem};
  --font-size-slider-controls: #{(60px / 16px) * 1rem};

  --line-height-base: #{(33 / 27)};
  --line-height-xlarge: #{(167 / 192)};
}

html{
  width: 100%;
  height: 100%;
  font-size: 1.1111vw;
}

@media (max-width: $smartphone) {
  html {
    font-size: 16px;
  }
  :root {
    --font-size-xxlarge:13vw;
    --font-size-xlarge:13vw;
    --font-size-large:32px;
  }
}


@mixin font-titular-xxlarge() {
  font-family: var(--font-sans);
  font-weight: 800;
  font-size: var(--font-size-xxlarge);
  line-height: var(--line-height-xlarge);
  text-transform: uppercase;
}

@mixin font-titular-xlarge() {
  font-family: var(--font-sans);
  font-weight: 800;
  font-size: var(--font-size-xlarge);
  line-height: var(--line-height-xlarge);
  text-transform: uppercase;
}

@mixin font-titular() {
  font-family: var(--font-sans);
  font-weight: 700;
  font-size: var(--font-size-large);
  line-height: 1;
  text-transform: uppercase;
}

@mixin font-p() {
  font-family: var(--font-sans);
  font-weight: 400;
  font-size: var(--font-size-base);
}