

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
* html .clearfix             { zoom: 1; } /* IE6 */
*:first-child+html .clearfix { zoom: 1; } /* IE7 */

/** {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing:grayscale;
}*/
/*body > * {
  -webkit-transform: translate3d(0,0,0);
}*/
input,button, embed, video, object {
  outline: none;
  border: 0 solid;
}
html {
  //-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  //-ms-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing:grayscale;
  //text-rendering: optimizeLegibility;
}
button {
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */
  -webkit-touch-callout: none;
  -moz-touch-callout: none;
  -ms-touch-callout: none;
  touch-callout: none;
}

.media-holder{
  --aspect-ratio: 100%;
  --aspect-ratio-mobile: 100%;

  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  background-position: center center;
  background-size: cover;

  padding-top: var(--aspect-ratio);

  @media (max-width: $smartphone) {
    padding-top: var(--aspect-ratio-mobile);
  }

  video,
  iframe,
  [data-item-load],
  [data-item-aspect-ratio],
  [data-item-loaded],
  [data-item-preload],
  [data-item-lazyload] {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: auto;
  }
}

.basic-media-holder{
  --aspect-ratio: 100%;
  --aspect-ratio-mobile: 100%;

  position: relative;
  width: 100%;

  padding-top: var(--aspect-ratio);

  @media (max-width: $smartphone) {
    padding-top: var(--aspect-ratio-mobile);
  }

  video,
  iframe,
  [data-item-load],
  [data-item-aspect-ratio],
  [data-item-loaded],
  [data-item-preload],
  [data-item-lazyload] {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: auto;
  }
}

[data-item-load],
[data-item-loaded],
[data-item-preload],
[data-item-lazyload] {
  &:not(img) {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}