.article-default {
  margin: var(--padding-m) 0;
  padding: 0 var(--padding-xl) 0;

  &.--p-two-columns {
    display: grid;
    grid-column-gap: var(--padding-s);
    grid-row-gap: 0;

    grid-template: auto / 1fr 1fr;
    --title-area: 1 / 3;

    .title {
      grid-column: var(--title-area);
    }

    p {
      margin: 0;
    }

    > div {
      p:not(:first-of-type) {
        margin-top: 1em;
      }
    }
  }

  &.--title-p-two-columns {
    display: grid;
    grid-column-gap: var(--padding-s);
    grid-row-gap: 0;
    grid-template: auto / 1fr 1fr;

    --title-area: 1 / 2;
    --p-area: 2 / 3;

    .title {
      grid-column: var(--title-area);
      grid-row: 1 / 9;
    }
    p {
      grid-column: var(--p-area);
    }

    .services-li {
      grid-column: var(--p-area);
    }
  }
}

.article-default.--services {
  position: relative;
  padding: 20vh 0 0;

  > .services-li {
    padding-right: var(--padding-xl);
  }
}

.services-li {
  figure {
    margin: var(--padding-s) 0;
  }
  margin-bottom: var(--padding-m);
}

.article-default .title {
  position: relative;
  @include font-titular();
  margin: 0 0 1em;

  .deco {
    position: absolute;
    top:0;
    left:0;
    color: var(--color-deco);
    z-index: -1;
  }
}

.article-default p {
  @include p--default();
}

.article-default__vinyl {
  position: relative;
  height: 100vh;
  grid-column: 1 / 2;
  grid-row: 1 / 99;
  perspective: 1000px;

  .vinyl-holder {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    transform-origin: left center;
  }

  .vinyl {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    transform-origin: 50% 50%;
    //transform: translate3d(-40%,0,0);
  }

  figure {
    position: absolute;
    width: 140%;
    height: auto;
    top:50%;
    left:0;
    transform: translate3d(-45%,-50%,0);
  }

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center center;
  }
}

@media (max-width: $smartphone) {
  .article-default {
    display: block !important;
    padding: 0 var(--padding-m);
    margin: var(--padding-l) 0;

    p {
      margin: 1em 0 !important;
    }
  }

  .article-default.--services {
    padding: 0 var(--padding-m) 0;

    > .services-li {
      padding: var(--padding-s) 0;
    }
  }

  .article-default__vinyl {
    position: relative !important;
  }

  .article-default__vinyl {

    height: auto;
    @include aspect-ratio-obj(1,1);
    margin: 0 0 0;
    overflow: hidden;

    .vinyl-holder {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      transform-origin: left center;
    }

    .vinyl {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      transform-origin: 50% 50%;
      //transform: translate3d(-40%,0,0);
    }

    figure {
      position: absolute;
      width: 100%;
      height: auto;
      top:0;
      left:0;
      transform: translate3d(0,-0%,0);
    }
  }
}